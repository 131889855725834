// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ui/command.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ui/command.tsx");
  import.meta.hot.lastModified = "1728334464000";
}
// REMIX HMR END

import * as React from 'react';
import { Command as CommandPrimitive } from 'cmdk';
import { Search } from 'lucide-react';
import { cn } from '~/lib/utils';
import { Dialog, DialogContent } from '~/components/ui/dialog';
const Command = React.forwardRef(_c = ({
  className,
  ...props
}, ref) => <CommandPrimitive ref={ref} className={cn('flex h-full w-full flex-col overflow-hidden rounded-md bg-white text-primary-950 dark:bg-primary-950 dark:text-primary-50', className)} {...props} />);
_c2 = Command;
Command.displayName = CommandPrimitive.displayName;
const CommandDialog = ({
  children,
  ...props
}) => {
  return <Dialog {...props}>
      <DialogContent className='overflow-hidden p-0 shadow-lg'>
        <Command className='[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-primary-500 [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5 dark:[&_[cmdk-group-heading]]:text-primary-400'>
          {children}
        </Command>
      </DialogContent>
    </Dialog>;
};
_c3 = CommandDialog;
const CommandInput = React.forwardRef(_c4 = ({
  className,
  ...props
}, ref) => <div className='flex items-center border-b px-3 py-3' cmdk-input-wrapper=''>
    <Search className='mr-2 h-4 w-4 shrink-0 opacity-50' />
    <CommandPrimitive.Input ref={ref} className={cn('flex h-9 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-primary-500 disabled:cursor-not-allowed disabled:opacity-50 dark:placeholder:text-primary-400', className)} {...props} />
  </div>);
_c5 = CommandInput;
CommandInput.displayName = CommandPrimitive.Input.displayName;
const CommandList = React.forwardRef(_c6 = ({
  className,
  ...props
}, ref) => <CommandPrimitive.List ref={ref} className={cn('max-h-[300px] overflow-y-auto overflow-x-hidden', className)} {...props} />);
_c7 = CommandList;
CommandList.displayName = CommandPrimitive.List.displayName;
const CommandEmpty = React.forwardRef(_c8 = (props, ref) => <CommandPrimitive.Empty ref={ref} className='py-6 text-center text-sm' {...props} />);
_c9 = CommandEmpty;
CommandEmpty.displayName = CommandPrimitive.Empty.displayName;
const CommandGroup = React.forwardRef(_c10 = ({
  className,
  ...props
}, ref) => <CommandPrimitive.Group ref={ref} className={cn('overflow-hidden p-1 text-primary-950 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-primary-500 dark:text-primary-50 dark:[&_[cmdk-group-heading]]:text-primary-400', className)} {...props} />);
_c11 = CommandGroup;
CommandGroup.displayName = CommandPrimitive.Group.displayName;
const CommandSeparator = React.forwardRef(_c12 = ({
  className,
  ...props
}, ref) => <CommandPrimitive.Separator ref={ref} className={cn('-mx-1 h-px bg-primary-200 dark:bg-primary-800', className)} {...props} />);
_c13 = CommandSeparator;
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;
const CommandItem = React.forwardRef(_c14 = ({
  className,
  ...props
}, ref) => <CommandPrimitive.Item ref={ref} className={cn('relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-primary-100 aria-selected:text-primary-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:aria-selected:bg-primary-800 dark:aria-selected:text-primary-50', className)} {...props} />);
_c15 = CommandItem;
CommandItem.displayName = CommandPrimitive.Item.displayName;
const CommandShortcut = ({
  className,
  ...props
}) => {
  return <span className={cn('ml-auto text-xs tracking-widest text-primary-500 dark:text-primary-400', className)} {...props} />;
};
_c16 = CommandShortcut;
CommandShortcut.displayName = 'CommandShortcut';
export { Command, CommandDialog, CommandInput, CommandList, CommandEmpty, CommandGroup, CommandItem, CommandShortcut, CommandSeparator };
var _c, _c2, _c3, _c4, _c5, _c6, _c7, _c8, _c9, _c10, _c11, _c12, _c13, _c14, _c15, _c16;
$RefreshReg$(_c, "Command$React.forwardRef");
$RefreshReg$(_c2, "Command");
$RefreshReg$(_c3, "CommandDialog");
$RefreshReg$(_c4, "CommandInput$React.forwardRef");
$RefreshReg$(_c5, "CommandInput");
$RefreshReg$(_c6, "CommandList$React.forwardRef");
$RefreshReg$(_c7, "CommandList");
$RefreshReg$(_c8, "CommandEmpty$React.forwardRef");
$RefreshReg$(_c9, "CommandEmpty");
$RefreshReg$(_c10, "CommandGroup$React.forwardRef");
$RefreshReg$(_c11, "CommandGroup");
$RefreshReg$(_c12, "CommandSeparator$React.forwardRef");
$RefreshReg$(_c13, "CommandSeparator");
$RefreshReg$(_c14, "CommandItem$React.forwardRef");
$RefreshReg$(_c15, "CommandItem");
$RefreshReg$(_c16, "CommandShortcut");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;